import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'

class AboutPage extends React.Component {
  render() {
    const { data } = this.props
    const {title} = data.site.siteMetadata

    return (
      <Layout location={this.props.location} title={title}>
      <h4>À propos</h4>
      <p>
        Ceci est un blog qui traite de mathématiques, en essayant dans la mesure du possible de
        les illustrer avec du code et des applications pratiques.
      </p>
      <p>
        C'est un choix éditorial — de nombreux résultats mathématiques n'ont pas d'application concrète aujourd'hui — 
        lié à mon activité et mes centres d'intérêts.
      </p>
      <p>
        Le niveau est variable. J'essaie néanmoins d'introduire les articles par un paragraphe plus accessible :
        ce n'est pas grave de ne pas tout comprendre, c'est déjà bien de savoir que les choses existent.
      </p>
      <h4>L'auteur — Thomas Chaumeny</h4>
      <p>
        Après des études scientifiques, j'ai eu l'occasion de travailler sur différents projets techniques et suis actuellement ingénieur en <i>freelance</i>&nbsp;
        (voir <a href="https://www.linkedin.com/in/thomas-chaumeny/" target="_blank">mon profil LinkedIn</a>).
      </p>
      <h4>Me contacter</h4>
      <p>
        N'hésitez pas à me contacter <a href="mailto:thomas@lipsum.dev">par email</a>.
      </p>
      </Layout>
    )
  }
}

export default AboutPage

export const pageQuery = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
